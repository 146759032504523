import { makeAutoObservable } from 'mobx';

class StoreBalance {
    isAllow = false
    isEditing = false
    balance = 0
    balancePlace = 0
    currency = ''
    type = ''

    api = {
        type: '', // 'error', 'warning'
        messages: [],
        hasError: false,
        hasMockError: false,
        configs: {
            isRetryOnAPIFail: true,
            maxRetryAttempt: 2,
            currentRetryAttempt: 0
        }
    }

    constructor() {
        makeAutoObservable(this)
    }
}

export const storeBalance = new StoreBalance()