import Raven from '@Raven'
import { _switchHydraEndpoint } from '../APIs'
import { Country } from '@Configs/Country'
import { isLocalhost } from '@Configs/ConfigsHeader'
import StoreCountry from '@Stores/StoreCountry'

const isDev = window.location.href.indexOf('localhost') > 0

export const getFooter = () => {
    return Raven.get(
        `https://member-calls2.unicity.com/footer/data/public/${StoreCountry.Country3()}`
    )
}

export const getOnlineCountry = () => {
    return Raven.get(
        `https://member-calls2-k.unicity.com/unishop-fn-misc/key_value/ushop_online_country`
    )
}
