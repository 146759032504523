import Raven from '../Raven';

export const sendFeedback = data => {
    const result = Raven.post(
        'unishop-fn-misc/feedback',
        data,
        { 'headers': { 'Content-Type': 'application/json' } }
    )

    return result
}