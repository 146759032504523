import { requestAsync } from '../../../utils/rest';
import StoreAuth from '@Stores/User/StoreAuth'

/** @ ==>  _ 로 치환 */
const serviceName = 'unishop@mo_setting';

const loadApi = async (params = {}) => await requestAsync(`LOAD_${serviceName.toUpperCase()}`, params);

const createApi = async params => await requestAsync(`INSERT_${serviceName.toUpperCase()}`, params);

const updateApi = async params => await requestAsync(`UPDATE_${serviceName.toUpperCase()}`, params);

const deleteApi = async params => await requestAsync(`DELETE_${serviceName.toUpperCase()}`, params);

const queryApi = async props => {
  const { gb, params } = props;
  let query;

  if(gb === 'load') {
    query = { sql : `select * 
                       from unishop_mo_setting 
                      where userId= '${StoreAuth.id}'`
            };  
  } else if(gb === 'insert') {
    query = {  sql: `insert into unishop_mo_setting (userId, cpv, list)
    values ('${StoreAuth.id}', '${params.cpv}', '${params.list}')`,
    }
  } else if (gb === 'update') {
    query = {
      sql: `update unishop_mo_setting
              set cpv = '${params.cpv}'
                , list = '${params.list}'
             where userId = '${StoreAuth.id}'
      `,
    };
  }

  try {
    return await requestAsync(`QUERY_SQL`, query);
  } catch (err) {}
};

const service = {
  load: loadApi,
  create: createApi,
  update: updateApi,
  delete: deleteApi,
  query: queryApi
};

export default service;
