import { reaction } from 'mobx';
import { devTools, store, storeManager, shopStore } from './MainStore';
import { language } from '@Language';
import { logw } from '../components/utils/PikaLog';
import { storeShopProfile } from './StoreShopProfile';
import { updateTranslation } from '../components/utils/Translator';
import Scroll from 'react-scroll';
import { storeGenealogy } from './StoreGenealogy';
import { storeSideBar } from './SideBarStore';

var scroll = Scroll.animateScroll;

// ** Watch app current language and set something if it changed. **
reaction(
    () => language.current,
    (lang, prevLang) => {
        if (lang) {
            language.previous = prevLang

            if (storeManager.isFooterStaticDone) {
                updateTranslation(lang)
            }
            
            if (prevLang === null || prevLang !== language.current) {
                store.imageLoading = true
            } else {
                if (prevLang !== language.current) {
                    store.imageLoading = false
                }
            }
        }
    }
)

reaction(() => shopStore.quickNavNext,
    isChanged => {
        let elem = document.getElementById('ref-' + shopStore.quickNavNext);
        if (elem) {
            const scrollTo = /a-to-z/.test(window.location.pathname) ? 50 : 140
            let amount = elem.offsetTop + scrollTo - 40
            scroll.scrollTo(amount);
            shopStore.quickNavNextAfter = shopStore.quickNavNext
        }
    }
)

reaction(() => store.shareThisCartMode,
    isChanged => {
        if (isChanged === false) {
            store.shopProDataSort = {}
            store.shopProfileCart = []
            store.shopProfilelistCart = []
            store.shopProfileTotal = 0
            store.shopProfileTotalPv = 0
            store.shopProfileID = 0
            storeShopProfile.hasDiscontinuedProduct = false
            
            if (/shopprofile\/edit/.test(window.location.pathname)) {
                shopStore.productDataSource.map((product, k) => {
                    if (product) {
                        product.qty = 0
                        product.proQty = 0
                    }

                    let shopProfileItem = store.shopProfileCart.find(item => item.item_code === product.item_code)
                    if (shopProfileItem) {
                        const buyQty = parseInt(shopProfileItem.qty)
                        product.qty = buyQty
                        product.proQty = buyQty
                    }

                    return product
                })
                
            }

        } else {
            shopStore.productDataSource.map((product, k) => {
                if (product) {
                    product.qty = 0
                    product.proQty = 0
                }

                let shopProfileItem = store.shopProfileCart.find(item => item.item_code === product.item_code)
                if (shopProfileItem) {
                    const buyQty = parseInt(shopProfileItem.qty)
                    product.qty = buyQty
                    product.proQty = buyQty
                }

                return product
            })
            shopStore.total = 0
            shopStore.totalPv = 0
        }
    }
)


reaction(
    () => store.showCart,
    isShow => {
        if(isShow === true) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = ''
        }
    }
)


