import { makeAutoObservable, reaction } from 'mobx'
import delay from 'delay'
import { checkToken } from '@Services/Network'
import StoreCountry from './StoreCountry'
import SessionAuth from './User/SessionAuth'
import StoreAuth from './User/StoreAuth'
import StoreUser from './User/StoreUser'

class StoreAPI {
    login = {
        isRedirecting: false
    }
    orderCalc = {
        isLoading: false,
        isResponsed: false,
        type: '', // 'error', 'warning'
        messages: [],
        hasError: false,
        hasMockError: false,
        configs: {
            isRetryOnAPIFail: true,
            maxRetryAttempt: 2,
            currentRetryAttempt: 0
        },

        get isTypeWarning() {
            return this.type === 'warning'
        },
        get isTypeError() {
            return this.type === 'error'
        },
        get isWarning() {
            return this.hasError && this.type === 'warning'
        },
        get isError() {
            return this.hasError && this.type === 'error'
        }
    }

    payment = {
        isLoading: false,
        message: ''
    }

    constructor() {
        makeAutoObservable(this)
    }

    setMessage = msg => {
        this.payment.message = msg
    }

    showPaymentLoading = () => {
        this.payment.isLoading = true
    }

    hidePaymentLoading = () => {
        this.payment.isLoading = false
    }

    IsMainApiLoaded = {
        language: false,
        footer: false,
        menu: false,
        country: false,
        filter: false
    }

    MainAPIPercentage = 0
    IsCoreReady = false

    ShowCountryPortal = false

    setApiLoaded(key, value) {
        this.IsMainApiLoaded[key] = value
    }

    IsApiLoaded() {
        return this.ShowCountryPortal ? 100 : this.MainAPIPercentage
    }

    SetPercentApiLoaded(value) {
        this.MainAPIPercentage = value
    }

    AutoIncrementPercent() {
        let percent = this.MainAPIPercentage
        percent += Math.random() + 1 - Math.random()
        percent = percent < 99 ? percent : 100

        this.MainAPIPercentage = percent
    }

    randomIntFromInterval(min, max) {
        // min and max included
        return Math.floor(Math.random() * (max - min + 1) + min)
    }

    MainApiStartLoad = {
        language: false,
        footer: false,
        menu: false,
        country: false,
        filter: false
    }

    ApiPercent = {
        language: 0,
        footer: 0,
        menu: 0,
        country: 0,
        filter: 0
    }

    GetPercentSummary() {
        let total = 0
        Object.keys(this.ApiPercent).map(v => {
            total += this.ApiPercent[v]
        })

        return total
    }

    TotalPercentPerApi() {
        return parseFloat(100 / this.GetTotalLoad())
    }

    GetTotalLoad() {
        let waitLoad = Object.keys(this.MainApiStartLoad).length
        if (!/menu/.test(window.location.pathname) || !StoreAuth.GetId() || StoreUser.CustomerData().IsCPWE()) {
            waitLoad -= 1
        }
        return waitLoad
    }
}
export const storeAPI = new StoreAPI()


reaction(
    () => storeAPI.MainApiStartLoad.country,
    (n, p, r) => {
        if (n === true) {
            const increament = async () => {
                if (storeAPI.ApiPercent.country >= 0 && storeAPI.ApiPercent.country < storeAPI.TotalPercentPerApi()) {
                    storeAPI.ApiPercent.country += 1
                    increament()
                }
            }
            increament()
        } else {
            if (storeAPI.IsMainApiLoaded.country) {
                storeAPI.ApiPercent.country = storeAPI.TotalPercentPerApi()
                r.dispose()
            }
        }
    }
)

reaction(
    () => storeAPI.MainApiStartLoad.footer,
    (n, p, r) => {
        if (n === true) {
            const increament = async () => {
                if (storeAPI.ApiPercent.footer >= 0 && storeAPI.ApiPercent.footer < storeAPI.TotalPercentPerApi()) {
                    storeAPI.ApiPercent.footer += 1
                    increament()
                }
            }
            increament()
        } else {
            if (storeAPI.IsMainApiLoaded.footer) {
                storeAPI.ApiPercent.footer = storeAPI.TotalPercentPerApi()
                r.dispose()
            }
        }
    }
)

reaction(
    () => storeAPI.MainApiStartLoad.language,
    (n, p, r) => {
        if (n === true) {
            const increament = async () => {
                if (storeAPI.ApiPercent.language >= 0 && storeAPI.ApiPercent.language < storeAPI.TotalPercentPerApi()) {
                    storeAPI.ApiPercent.language += 1
                    increament()
                }
            }
            increament()
        } else {
            if (storeAPI.IsMainApiLoaded.language) {
                storeAPI.ApiPercent.language = storeAPI.TotalPercentPerApi()
                r.dispose()
            }
        }
    }
)

reaction(
    () => storeAPI.MainApiStartLoad.filter,
    (n, p, r) => {
        if (n === true) {
            const increament = async () => {
                if (storeAPI.ApiPercent.filter >= 0 && storeAPI.ApiPercent.filter < storeAPI.TotalPercentPerApi()) {
                    storeAPI.ApiPercent.filter += 1
                    increament()
                }
            }
            increament()
        } else {
            if (storeAPI.IsMainApiLoaded.filter) {
                storeAPI.ApiPercent.filter = storeAPI.TotalPercentPerApi()
                r.dispose()
            }
        }
    }
)

reaction(
    () => storeAPI.MainApiStartLoad.menu,
    (n, p, r) => {
        if (n === true) {
            const increament = async () => {
                if (storeAPI.ApiPercent.menu >= 0 && storeAPI.ApiPercent.menu < storeAPI.TotalPercentPerApi() && storeAPI.IsMainApiLoaded.menu === false) {
                    storeAPI.ApiPercent.menu += 1
                    await delay (200)
                    increament()
                } else {
                    if (storeAPI.IsMainApiLoaded.menu) {
                        storeAPI.ApiPercent.menu = storeAPI.TotalPercentPerApi()
                    }
                }
            }
            increament()
        } else {
            if (storeAPI.IsMainApiLoaded.menu) {
                if (SessionAuth.GetLoginExtend('token')) {
                    checkToken(SessionAuth.GetLoginExtend('token'), StoreCountry.Country3(), SessionAuth.GetLoginExtend('id'))
                        .then(response => {
                            const data = response.json()
                            data.then(d => {
                                SessionAuth.SetLoginExtend('token', d.token)
                                sessionStorage.setItem('customerToken', d.token)
                            })
                        })
                        .catch(() => {
                            localStorage.clear()
                            sessionStorage.clear()
                            window.location.reload()
                        })
                }

                storeAPI.ApiPercent.menu = storeAPI.TotalPercentPerApi()
                r.dispose()
            }
        }
    }
)

reaction(
    () => storeAPI.GetPercentSummary(),
    (n, p, r) => {
        if (n >= 100) {
            const a = async () => {
                storeAPI.MainAPIPercentage = 95
                if (storeAPI.IsMainApiLoaded.menu === true) {
                    
                    await delay(500)
                    storeAPI.MainAPIPercentage = 100
                    r.dispose()
                }
            }
            a()
        }
    }
)

