import { RankConfigs } from '@Components/genealogy/RankConfigs'
import { inArray } from '@Config'
import { getNativeName } from '@GlobalHelpers'
import { get, lowerCase } from 'lodash'
import { makeAutoObservable } from 'mobx'
import StoreCountry from './StoreCountry'
import { storeFilter } from './StoreFilter'

class storeGenealogy {
    showLoader = false
    breadcrumbs = []
    breadcrumbsName = []
    breadcrumbsNativeName = []
    filters = []
    sort_by = 'id'
    show_all = true
    firstLoaded = false
    show_vip = true
    prevDataSource = []
    show_card = false
    prevBreadcrumbs = null
    breadcrumbsHistory = []
    showBreadcrumbs = false
    showBookmark = false
    downlineList = []
    view = 'dashboard'
    searchBa = ''
    showFullName = true
    showSearch = false
    reInit = false

    resetTable = false
    takeASnapshot = false

    showSwitcherFilter = false

    switchFilterIdAndName = 'id'

    hasDownline = false

    getFreshData = false
    getFreshLargeLevelData = false

    items = []
    itemsSortOV = []
    itemsSortOVWithHideZero = []
    itemsHideZero = []
    successTracker = []
    defaultItem = []
    filterItems = null
    viewRef = null

    closeHeaderMenu = false
    imageList = {}

    age = 1
    located = false
    fetchingData = false
    hideAlColumn = true
    didFiltered = false
    periodTableSelect = 0
    initFilter = false

    genealogyLevelWidth = 354

    forceShowSkeleton = false

    genealogyLevel = []
    genealogyLevelNext = null
    genealogyLevelPrev = null

    genealogyFiltered = []

    reAssigned = false
    context = ''

    showSearchBox = false

    showProfilePicture = true

    favorites = []

    hideSuccessTracker = false
    loadingSuccessTrackerDashboard = false

    SetShowProfilePicture(value) {
        this.showProfilePicture = true
    }

    SetInitData(data) {
        this.items = data.genealogy.items
        this.itemsHideZero = data.genealogy.items
        this.itemsSortOV = data.genealogy.items
        this.itemsSortOVWithHideZero = data.genealogy.items
        this.successTracker = data.achievementsHistory
        this.defaultItem = data.genealogy.items
    }

    SetRefresh(value) {
        this.getFreshData = value
    }

    SetLargeLevelRefresh(value) {
        this.getFreshLargeLevelData = value
    }

    get RefreshData() {
        return this.getFreshData
    }

    get ShowSkeleton() {
        return this.forceShowSkeleton
    }

    SetShowSkeleton(value) {
        this.forceShowSkeleton = value
    }

    GetGenealogyLevel() {
        if (this.genealogyLevel.length === 0) {
            this.genealogyLevel = this.items
        }
        return this.genealogyLevel || []
    }

    GetGenealogyLevelWidth() {
        return this.genealogyLevelWidth
    }

    SetGenealogyItem(key, value) {
        this[key] = value

        if (key === 'items') {
            this.genealogyLevel = this.items
            if (this.items?.length > 256) {
                this.SetShowProfilePicture(false)
            } else {
                this.SetShowProfilePicture(true)
            }
        }
    }

    GetUserType(item) {
        const ranks = RankConfigs.GenealogyRanks.all
        let rank = ''
        if (item.customer) {
            rank = get(item, 'customer.cumulativeMetricsProfile.highestRankShort', 'etc') //
        } else {
            rank = get(item, 'cumulativeMetricsProfile.highestRankShort', 'etc') //
        }
        let dictionaryOfRank = inArray(rank.toLowerCase(), ranks) ? rank.toLowerCase() : RankConfigs.GenealogyRanks.lowest

        if (item.ushopDistStatus === 'H') {
            dictionaryOfRank = 'u_all_wholesale_customer'
            return dictionaryOfRank
        } else {
            return `u_all_genealogy_rank_${dictionaryOfRank.toLowerCase()}`
        }
    }

    GetRank(customer, returnAsDictionary = true, view = 'genealogy') {
        const ranks = view === 'genealogy' ? RankConfigs.GenealogyRanks.all : RankConfigs.SuccessTranckerRanks.all
        const lowestRank = view === 'genealogy' ? RankConfigs.GenealogyRanks.lowest : RankConfigs.SuccessTranckerRanks.lowest
        let rank = get(customer, 'cumulativeMetricsProfile.highestRankShort', lowestRank) // get(object, 'object.childs...', default value)
        let dictionaryOfRank = inArray(rank.toLowerCase(), ranks) ? rank : lowestRank

        return returnAsDictionary ? `u_all_genealogy_rank_${dictionaryOfRank.toLowerCase()}` : dictionaryOfRank.toLowerCase()
    }

    GetRankByRankName(rankName, returnAsDictionary = true, view = 'genealogy') {
        const ranks = view === 'genealogy' ? RankConfigs.GenealogyRanks.all : RankConfigs.SuccessTranckerRanks.all
        const lowestRank = view === 'genealogy' ? RankConfigs.GenealogyRanks.lowest : RankConfigs.SuccessTranckerRanks.lowest
        let dictionaryOfRank = inArray(rankName.toLowerCase(), ranks) ? rankName : lowestRank
        return returnAsDictionary ? `u_all_genealogy_rank_${dictionaryOfRank.toLowerCase()}` : dictionaryOfRank.toLowerCase()
    }

    GetUshopDistStatus(item) {
        return item.ushopDistStatus || item.cumulativeMetricsProfile?.highestRankShort || RankConfigs.GenealogyRanks.lowest
    }

    reset() {
        this.filterItems = null
        this.defaultItem = []
        this.itemsHideZero = []
        this.itemsSortOV = []
        this.itemsSortOVWithHideZero = []
        this.successTracker = []
        this.items = []
        this.age = 0
        this.located = false
    }

    resetItems() {
        this.items = []
        this.defaultItem = []
        this.itemsHideZero = []
        this.itemsSortOV = []
        this.itemsSortOVWithHideZero = []
        this.genealogyLevel = []
    }

    SearchByNameOrId(context, elementHidden = false) {
        if (context.trim().length === 0 && elementHidden === true) {
            this.genealogyFiltered = this.defaultItem
            // this.reAssigned = true
            if (elementHidden === true) {
                this.defaultItem.map(v => {
                    let elm = document.getElementById(`user-${v.customer.id.unicity}`)
                    if (elm) {
                        elm.style.display = 'inherit'
                    }
                })
                const searchNotFoundElm = document.getElementById('search-not-found')
                if (searchNotFoundElm) {
                    searchNotFoundElm.style.display = 'none'
                }
            }
        } else {
            let temp = {}

            if (elementHidden === false) {
                this.defaultItem.map(v => {
                    let isMatchedId = v.customer.id.unicity.search(lowerCase(context)) > -1
                    let isMatchedFullNameEn = lowerCase(v.customer.humanName.fullName).search(lowerCase(context)) > -1
                    let nativeName = getNativeName(v.customer.humanName, 'nativeName')
                    let isMatchedNativeName = lowerCase(nativeName).search(lowerCase(context)) > -1

                    if (isMatchedId || isMatchedFullNameEn || isMatchedNativeName) {
                        temp[v.customer.id.unicity] = v
                    }
                })

                temp = Object.values(temp)
                temp.unshift(this.defaultItem[0])

                let newSorted = []
                this.defaultItem.map(v => {
                    if (temp.find(v2 => v2.customer.id.unicity === v.customer.id.unicity)) {
                        newSorted.push(v)
                    }
                })

                this.genealogyFiltered = newSorted
                this.reAssigned = true
                this.context = context
            } else {
                let count = this.defaultItem.length
                this.defaultItem.map((v, k) => {
                    let isMatchedId = v.customer.id.unicity.search(lowerCase(context)) > -1
                    let isMatchedFullNameEn = lowerCase(v.customer.humanName.fullName).search(lowerCase(context)) > -1
                    let nativeName = getNativeName(v.customer.humanName, 'nativeName')
                    let isMatchedNativeName = lowerCase(nativeName).search(lowerCase(context)) > -1
                    let elm = document.getElementById(`user-${v.customer.id.unicity}`)
                    if (isMatchedId || isMatchedFullNameEn || isMatchedNativeName) {
                        if (elm) {
                            elm.style.display = 'inherit'
                        }
                    } else {
                        if (elm && k > 0) {
                            elm.style.display = 'none'
                        }
                    }
                })

                if (count > 0) {
                    const searchNotFoundElm = document.getElementById('search-not-found')
                    if (searchNotFoundElm) {
                        searchNotFoundElm.style.display = 'none'
                    }
                } else {
                    const searchNotFoundElm = document.getElementById('search-not-found')
                    if (searchNotFoundElm) {
                        searchNotFoundElm.style.display = 'flex'
                    }
                }
            }
        }
    }

    NeedToReAssign() {
        return this.reAssigned
    }

    SetReAssign(value) {
        this.reAssigned = value
    }

    ShowSearchBox() {
        return this.showSearchBox
    }

    SetShowSearchBox(value) {
        this.showSearchBox = value
    }

    SetView(value) {
        this.view = value
    }

    GetView() {
        return this.view
    }

    SetFetchingData(value) {
        this.fetchingData = value
    }

    GetFetchingData() {
        return this.fetchingData
    }

    constructor() {
        makeAutoObservable(this)
    }
}

export default new storeGenealogy()
