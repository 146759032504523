import { makeAutoObservable, entries, values, get as mget, set as mset, has as mhas, remove as mremove } from 'mobx';
import { isSomething, isNumber, isString, isNaN, isNothing } from '@Utils/Utils';

class StoreFirebase {
    cart = {}
    snapshotValue = {}
    limitedQty = {
        min: 0,
        max: 999
    }
    totalPrice = 0
    totalPV = 0
    totalQty = 0
    
    constructor() {
        makeAutoObservable(this)
    }

    get isCartEmpty() {
        return isNothing(this.cart)
    }

    // * -------------------
    get cartList() {
        const list = []
        const map = entries(this.cart)
        map.map(([key, qty]) => {
            list.push({ id: key, qty: parseInt(qty) })
        })
        return list
    }
    get cartValues() {
        return values(this.cart)
    }
    get cartEntries() {
        return entries(this.cart)
    }
    // * -------------------

    clearCart() {
        this.cart = {}
    }

    getCartItem(itemCode) {
        return mget(this.cart, itemCode)
    }

    hasCartItem(itemCode) {
        return mhas(this.cart, itemCode)
    }

    getCartItemQty(itemCode) {
        if (this.isCartEmpty) return 0
        const item = mget(this.cart, itemCode)
        if (isSomething(item)) {
            const parsedQty = parseInt(item.qty)
            if (isNaN(parsedQty)) return 0
            else return parsedQty
        } else {
            return 0
        }
    }
    // add
    addToCart(itemCode, qty = 1) {
        if (this.hasCartItem(itemCode)) {
            // limited min qty
            const currentQty = this.getCartItemQty(itemCode)
            let newQty = currentQty + qty
            if (newQty >= this.limitedQty.max) {
                newQty = this.limitedQty.max
            }
            mset(this.cart, { 
                [itemCode]: {
                    ...this.cart[itemCode],
                    qty: newQty
                }
            })
        }
    }
    // remove
    removeFromCart(itemCode, qty = 1) {
        // limited min qty
        const currentQty = this.getCartItemQty(itemCode)
        let newQty = currentQty - qty
        if (newQty <= this.limitedQty.min) {
            newQty = this.limitedQty.min
        }
        if (newQty > this.limitedQty.min) {
            mset(this.cart, { [itemCode]: newQty })
        } else {
            mremove(this.cart, itemCode)
        }
    }
    // direct change
    assignToCart(itemCode, qty) {
        let newQty = qty
        let acceptedItemCode = itemCode
        if (isNumber(itemCode)) {
            acceptedItemCode = `${itemCode}`
        }
        if (isString(acceptedItemCode)) {
             // limited qty
            if (newQty >= this.limitedQty.max) {
                newQty = this.limitedQty.max
            }
            if (newQty <= this.limitedQty.min) {
                newQty = this.limitedQty.min
            }
            if (newQty <= 0) {
                mremove(this.cart, itemCode)
            } else {
                mset(this.cart, { [itemCode]: newQty })
            }
        }
    }
}

export const storeFirebase = new StoreFirebase()